/* Bar along bottom of page (external links and copyright). */
#header {
    background-color: #393E46;
    color: #EEEEEE;

    height: 80px;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 1000;

    text-align: center;
    
    box-shadow: 0px 5px rgba(0,0,0,0.3);
}

#header h1 {
    float: left;
    padding: 0px 10px;

    line-height: 38px;
    margin: 21px 0px;
}

#header nav {
    float: right;
    height: 100%;
    display: flex;
    justify-content: center;
}

#header nav button {
    font-size: 1.5em;
    padding: 0px 10px;
    margin: 0px 10px;
    background-color: #393E46;
    color: #EEEEEE;

    border-radius: 12px;
    align-self: center;
}

#header nav a {
    align-self: center;
}

@media (max-width: 800px) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
    #header h1 {
        font-size: 1em;
        line-height: 20px;
        margin: 10px 0px;
    }

    #header nav {
        line-height: 20px;
    }

    #header nav a {
        font-size: 0.9em;
    }

    #header {
        height: 42px;
    }

    #header nav button {
        font-size: 15px;
        height: 35x;

        margin: 0px 5px;
    }
  }