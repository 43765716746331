/* Bar along bottom of page (external links and copyright). */
#footer {
    background-color: #393E46;
    color: #EEEEEE;
    
	opacity: 1.0;

    height: 64px;

    text-align: center;
}

/* Icons for external resources (twitter, email, linkedin, github). */
#footer nav a {
    padding: 5px 5px;
}