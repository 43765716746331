.timeline_event {
    width: 100%;
}

.event_image {
    float: left;
    width: 25%;
}

.event_image img {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.event_image h3 {
    font-size: medium;
}

.event_description {
    float: right;
    width: 60%;
}

.event_description p {
    font-size: medium;
}

.year {
    float: left;
    
    width: 15%;
    height: 500px;

    margin: auto;
}

.year .circle {
    background: #00ADB5;
    
    width: 75%;
    aspect-ratio: 1/1;
    border-radius: 50%;

    margin: auto;
}

.year .circle h1 {
    font-size: 90%;
    margin: auto;
    padding: 50% 0%;
}

.year .trail {
    background: #00ADB5;

    width: 15%;
    height: 690px;

    margin: auto;
}

.event_description ul {
    list-style-type: circle;
}

.event_description li {
    height: auto;
    margin: 15px;
    text-align: justify;
}