.App {
  text-align: center;
}

#main {
  background-color: #222831;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #EEEEEE;
}

#intro {
  height: 90vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

#intro h1 {
  padding: 0px 10px;
  margin: 10% 0% 15% 0%;
}

#intro h2 {
  padding: 0px 10px;
  margin: 5% 0%;
}
